import { Box, InputLabel, Stack, TextField } from '@mui/material'
import { useGetCheckInPolicyByRewardsProgramsId } from 'hooks/api/RewardProgram/useGetCheckInPolicyByRewardsProgramsId'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { useMemo, useState } from 'react'
import { type FullCheckInPolicy } from 'types/api'
import { RewardsProgramToolContent } from './RewardsProgramToolContent'
import { useDebounce } from '@uidotdev/usehooks'
import { isPresent } from '@jjvgaming/player-payback-library'

export interface PolicyType {
  CheckInThresholdMin: FullCheckInPolicy | null
  CheckInsDailyMax: FullCheckInPolicy | null
  PatronCheckInPointBonus: FullCheckInPolicy | null
  PatronBirthdayMilestoneBonus: FullCheckInPolicy | null
}

export const RewardsProgramTool = () => {
  const [rewardsProgramId, setRewardsProgramId] = useState<number | undefined>(
    undefined
  )
  const rewardsProgramIdString = useMemo(() => {
    if (rewardsProgramId === undefined) {
      return ''
    }
    return String(rewardsProgramId)
  }, [rewardsProgramId])
  const debouncedRewardsProgramId = useDebounce(rewardsProgramId, 500)
  const rewardsProgramQuery = useGetRewardProgramById({
    rewardProgramId: debouncedRewardsProgramId,
  })
  const policiesQuery = useGetCheckInPolicyByRewardsProgramsId({
    rewardProgramId: debouncedRewardsProgramId,
  })

  return (
    <Box>
      <Stack direction={'column'} spacing={2}>
        <Box>
          <InputLabel htmlFor="rewardsProgramId">Rewards Program ID</InputLabel>
          <TextField
            name="rewardsProgramId"
            id="rewardsProgramId"
            value={rewardsProgramIdString}
            onChange={(event) =>
              setRewardsProgramId(
                event.target.value === ''
                  ? undefined
                  : Number(event.target.value)
              )
            }
          />
        </Box>
      </Stack>

      {isPresent(rewardsProgramQuery.data) && isPresent(policiesQuery.data) && (
        <RewardsProgramToolContent
          rewardsProgram={rewardsProgramQuery.data}
          checkInPolicies={policiesQuery.data}
        />
      )}
    </Box>
  )
}
