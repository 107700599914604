import { Box, InputLabel, Stack, TextField, Typography } from '@mui/material'
import { useGetPatronById } from 'hooks/api/Patron/useGetPatronById'
import { isPresent } from '@jjvgaming/player-payback-library'
import { useMemo, useState } from 'react'
import { PatronToolContent } from './PatronToolContent'
import { useDebounce } from '@uidotdev/usehooks'
import { isNil } from 'lodash'

export const PatronTool = () => {
  const [patronId, setPatronId] = useState<undefined | number>(undefined)
  const patronIdString = useMemo(() => {
    if (isNil(patronId)) {
      return ''
    }
    return String(patronId)
  }, [patronId])
  const debouncedPatronId = useDebounce(patronId, 500)
  const patronQuery = useGetPatronById({
    id: debouncedPatronId,
  })

  return (
    <Stack direction={'column'} spacing={2}>
      <Box>
        <InputLabel htmlFor="patronId">Patron ID</InputLabel>
        <TextField
          name="patronId"
          id="patronId"
          value={patronIdString}
          onChange={(e) => {
            if (/\d+/.test(e.target.value)) {
              setPatronId(Number(e.target.value))
            } else {
              setPatronId(undefined)
            }
          }}
        />
        {patronQuery.isError && (
          <Typography>Error occured fetching patron</Typography>
        )}
      </Box>
      {patronQuery.isSuccess && isPresent(patronQuery.data) && (
        <PatronToolContent patron={patronQuery.data} />
      )}
    </Stack>
  )
}
