import { type UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'

export const useResetSweepstakesByPatron = ({
  onSuccess,
  onError,
}: UseMutationOptions<undefined, ApiError, { patronId: number }>) => {
  const deleteCall = useTypedApiClient({
    path: '/admin/utilities/patron/{patronId}/reset-sweepstakes',
    method: 'delete',
  })

  return useMutation<undefined, ApiError, { patronId: number }>({
    mutationKey: ['/admin/utilities/patron/{patronId}/reset-sweepstakes'],
    mutationFn: async ({ patronId }) => {
      await deleteCall({ patronId })
    },
    onSuccess,
    onError,
  })
}
