import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { ActivityButton } from 'components/ActivityButton'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'stores/useSnackbar'
import * as yup from 'yup'

const useDeleteAccountByPhone = () => {
  const deleteAccountByPhone = useTypedApiClient({
    path: '/admin/patrons-by-phone/{phone}',
    method: 'delete',
  })
  const setMessage = useSnackbar((state) => state.setMessage)

  return useMutation<boolean, ApiError, { phoneNumber: number }>({
    mutationFn: async ({ phoneNumber }) => {
      const result = await deleteAccountByPhone({ phone: phoneNumber })
      return result.data
    },
    onSuccess: (_data, vars) => {
      setMessage(
        `Account with phone number ${vars.phoneNumber} deleted`,
        'success'
      )
    },
    onError: (error) => {
      if (error.status === 404) {
        setMessage('Account not found', 'error')
      } else {
        setMessage('An error occurred', 'error')
      }
    },
  })
}

export const DeleteB2cAccount = () => {
  const schema = yup.object({
    phoneNumber: yup
      .string()
      .required('Phone number is required')
      .matches(
        /^\d{3}-\d{3}-\d{4}$|^\d{10}$/,
        'Phone number must be in the format ###-###-#### or #########'
      ),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })
  const {
    mutate: deleteAccountByPhone,
    isPending: deleteAccountByPhoneIsPending,
  } = useDeleteAccountByPhone()

  return (
    <Stack gap={2}>
      <Typography variant="body-1" className="text-secondary">
        Delete an account in B2C and PPB by phone number
      </Typography>
      <FormControl>
        <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
        <TextField
          id="phoneNumber"
          placeholder="###-###-####"
          type="tel"
          autoComplete="off"
          error={Boolean(errors.phoneNumber)}
          {...register('phoneNumber')}
        />
        {errors.phoneNumber?.message && (
          <FormFieldErrorMessage message={errors.phoneNumber?.message} />
        )}
      </FormControl>

      <Box>
        <ActivityButton
          type="submit"
          variant="contained"
          active={deleteAccountByPhoneIsPending}
          onClick={handleSubmit((data) => {
            const phoneNumber = parseInt(data.phoneNumber.replace(/\D+/g, ''))
            deleteAccountByPhone({ phoneNumber })
          })}
        >
          Delete Account
        </ActivityButton>
      </Box>
    </Stack>
  )
}
