import { Page } from 'components/Shared/Page'
import { PatronTool } from './PatronTool'
import { RewardsProgramTool } from './RewardsProgramTool'
import { Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import { DeleteB2cAccount } from './DeleteB2cAccount'

type TabType = 'patron tools' | 'rewards program tools' | 'delete b2c account'

export const SupportTools = () => {
  const [activeTab, setActiveTab] = useState<TabType>('patron tools')

  return (
    <Page>
      <Typography variant="h1">Support Tools</Typography>
      <Tabs
        value={activeTab}
        onChange={(_event, newValue: TabType) => setActiveTab(newValue)}
        className="mb-4"
      >
        <Tab label="Patron Tools" value="patron tools" />
        <Tab label="Rewards Program Tools" value="rewards program tools" />
        <Tab label="Delete Patron + B2C Account" value="delete b2c account" />
      </Tabs>

      {activeTab === 'patron tools' && <PatronTool />}
      {activeTab === 'rewards program tools' && <RewardsProgramTool />}
      {activeTab === 'delete b2c account' && <DeleteB2cAccount />}
    </Page>
  )
}
