import { Box, Button, MenuItem, Select, Stack, Typography } from '@mui/material'
import { isNil } from 'lodash'
import { type Patron } from 'types/api'
import { usePutPatronTier } from 'hooks/api/Patron/usePutPatronTier'
import { useResetSweepstakesByPatron } from 'hooks/api/Sweepstake/useResetSweepstakesByPatron'
import { usePatchPointLedgerReconcile } from 'hooks/api/usePatchPointLedgerReconcile'
import { useSnackbar } from 'stores/useSnackbar'
import { useQueryClient } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { useGetTiers } from 'hooks/api/useGetTiers'
import { DataGrid } from '@mui/x-data-grid'
import { ActivityButton } from 'components/ActivityButton'
import { useGetPatronById } from 'hooks/api/Patron/useGetPatronById'

export const PatronToolContent = ({ patron }: { patron: Patron }) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const qc = useQueryClient()
  const [selectedTier, setSelectedTier] = useState<number | null>(null)
  const selectedTierString = useMemo(() => {
    if (isNil(selectedTier)) {
      return ''
    }

    return String(selectedTier)
  }, [selectedTier])
  const tiersQuery = useGetTiers()
  const tiers = tiersQuery.data
  const patronQuery = useGetPatronById({ id: Number(patron.id) }).data

  const reconcilePatronLedgerSummaryMutation = usePatchPointLedgerReconcile({
    onError: () => {
      setSnackbarMessage(
        'An error occurred while processing reconciled Patron Ledger summary',
        'error'
      )
    },
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ['/admin/patronaccount/detail'] })
      setSnackbarMessage(
        'Successfully reconciled Patron Ledger summary',
        'success'
      )
    },
  })

  const putPatronMutation = usePutPatronTier({
    onError: () => {
      setSnackbarMessage(
        'An error occurred while processing changed Patron Tier',
        'error'
      )
    },
    onSuccess: async () => {
      await qc.invalidateQueries({
        queryKey: ['/admin/patronaccount/detail'],
      })
      setSelectedTier(null)
      setSnackbarMessage('Successfully changed Patron Tier', 'success')
    },
  })

  const resetSweepstakesbyPatronMutations = useResetSweepstakesByPatron({
    onError: () => {
      setSnackbarMessage(
        'An error occurred while processing reset Sweepstake Entries',
        'error'
      )
    },
    onSuccess: async () => {
      await qc.invalidateQueries({ queryKey: ['/admin/patronaccount/detail'] })
      setSnackbarMessage('Successfully reset Sweepstake Entries', 'success')
    },
  })

  if (
    isNil(patron) ||
    isNil(patron.pointsLedgerSummaries) ||
    isNil(patronQuery)
  ) {
    return <Typography variant="body-1">No data</Typography>
  }

  return (
    <Box>
      <Box className="my-4 border-2 p-4 rounded-md border-lightGray">
        <Typography variant="h3">Point Ledger Summaries</Typography>
        <DataGrid
          hideFooter
          className="mt-4"
          columns={[
            {
              headerName: 'Rewards Program',
              field: 'rewardsProgram',
              valueGetter: (params) => {
                if (isNil(params.row.rewardsProgram)) {
                  return 'N/A'
                }

                return params.row.rewardsProgram.name
              },
              flex: 1,
            },
            {
              headerName: 'Balance',
              field: 'balance',
              valueGetter: (params) => params.row.balance,
              flex: 0,
            },
          ]}
          rows={patron.pointsLedgerSummaries}
        />

        <Stack direction={'row'} gap={2} my={2}>
          <Button
            variant="contained"
            onClick={async () => {
              await reconcilePatronLedgerSummaryMutation.mutateAsync({
                patronId: Number(patron.id),
              })
            }}
          >
            Reset Ledger summary
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              await resetSweepstakesbyPatronMutations.mutateAsync({
                patronId: Number(patron.id),
              })
            }}
          >
            Reset Sweepstakes Entry
          </Button>
        </Stack>
      </Box>

      <Box className="my-4 border-2 p-4 rounded-md border-lightGray">
        <Typography variant="h3">Tier</Typography>
        <Typography variant="body-1" py={1}>
          Current Tier: {patronQuery.currentTier?.tier?.type ?? 'Tier Not Set'}
        </Typography>
        <Stack direction={'row'} gap={2} alignItems={'center'}>
          <Select
            sx={{ minWidth: 150 }}
            value={selectedTierString}
            onChange={(event) => setSelectedTier(Number(event.target.value))}
          >
            {tiers?.map((tier) => (
              <MenuItem
                key={tier.id}
                value={tier.id}
                disabled={tier.id === patronQuery.currentTier?.tier?.id}
              >
                {tier.type}{' '}
                {tier.id === patronQuery.currentTier?.tier?.id
                  ? '(Current)'
                  : ''}
              </MenuItem>
            ))}
          </Select>
          <ActivityButton
            variant="contained"
            disabled={isNil(selectedTier)}
            active={putPatronMutation.isPending}
            onClick={async () => {
              if (isNil(selectedTier)) {
                throw new Error("Selected Tier can't be null")
              }
              await putPatronMutation.mutateAsync({
                id: patron.id,
                tierId: selectedTier,
              })
            }}
          >
            Update Tier
          </ActivityButton>
        </Stack>
      </Box>
    </Box>
  )
}
