import { type PointsLedgerSummary } from 'types/api'
import { type UseMutationOptions, useMutation } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type ApiError } from 'openapi-typescript-fetch'

type usePutRewardProps = UseMutationOptions<
  PointsLedgerSummary[],
  ApiError,
  { patronId: number }
>

export const usePatchPointLedgerReconcile = ({
  onSuccess,
  onError,
}: usePutRewardProps) => {
  const patchClient = useTypedApiClient({
    path: '/admin/utilities/points-ledgers/{patronId}/reconcile',
    method: 'patch',
  })
  return useMutation({
    mutationFn: async ({ patronId }: { patronId: number }) => {
      const { data } = await patchClient({ patronId })
      return data
    },
    onSuccess: async (data, vars, context) => {
      await onSuccess?.(data, vars, context)
    },
    onError,
  })
}
